import i18n from "../i18n";
import basicRequest from "../services/basicRequest";
import settings from "../configs/settings";
import axios from "axios";

export const api = {
    countriesPrefixes: async () => {
        const request = new basicRequest();
        return await request.get('/countries');
    },
    roles: async () => {
        const request = new basicRequest();
        return await request.get('/leads/roles');
    },
    vendorTypes: async () => {
        const request = new basicRequest();
        return await request.get('/leads/vendortypes');
    },
    vehicles: async () => {
        const request = new basicRequest();
        return await request.get('/vehicles');
    },
    saveLead: async (data) => {
        const request = new basicRequest();
        return await request.post('/leads', data);
    },
    getAccessToken: async () => {        
        const request = new basicRequest();
        return await request.post('/token', {
            client_id: settings.loginClientId,
            grant_type: settings.loginGrantType,
            scope: settings.loginScope,
        });
    },
    getEnterpriseData: async (token, countryCode, vat) => {
        return await axios.post(settings.viesUrl, {
            "countryCode": countryCode,
            "vatNumber": vat
        }, { headers: { "Authorization": "Bearer " + token } })
            .then((res) => {
                return res.data
            })
            .catch(e => {
                console.log('error', e);
                return {
                    success: false,
                }
            })
    },
    getAddressDataFromNominatim: async (address) => {
        return await axios.get(settings.nominatimUrl, {
            params: {
                addressdetails: 1,
                q: address,
                format: 'json',
            }
        }).then((res) => {
            return {
                success: true,
                data: res.data
            }
        }).catch((e) => {
            console.log('error', e);
            return {
                success: false
            }
        })
    }
}