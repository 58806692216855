const settings = window.Environment === 'local' ? {
    baseApiUrl: process.env.REACT_APP_BASE_API_URL,
    privacyUrlIta: process.env.REACT_APP_PRIVACY_URL_ITA,
    privacyUrlEng: process.env.REACT_APP_PRIVACY_URL_ENG,
    loginUrl: process.env.REACT_APP_LOGIN_URL,
    loginClientId: process.env.REACT_APP_LOGIN_CLIENT_ID,
    loginGrantType: process.env.REACT_APP_LOGIN_GRANT_TYPE,
    loginScope: process.env.REACT_APP_LOGIN_SCOPE,
    viesUrl: process.env.REACT_APP_VIES_URL,
    nominatimUrl: process.env.REACT_APP_NOMINATIM_URL
} : window.Settings;

export default settings;